.c-tag {
  padding: $tag-padding-y $tag-padding-x;
  font-size: $tag-font-size;
  font-weight: $tag-font-weight;
  text-transform: $tag-text-transform;
  text-decoration: $tag-text-transform;
  line-height: $tag-text-transform;
  letter-spacing: $tag-letter-spacing;
  border-radius: $tag-radius;
  color: $tag-color;

  &--discount {
    font-size: $tag-discount-font-size;
    font-weight: $tag-discount-font-weight;
    color: $tag-discount-color;
    background-color: $tag-discount-bg;
    border: $tag-border-width solid $tag-discount-border;
  }

  &--promo {
    font-size: $tag-promo-font-size;
    font-weight: $tag-promo-font-weight;
    color: $tag-promo-color;
    background-color: $tag-promo-bg;
    border: $tag-border-width solid $tag-promo-border;
  }

  &--new {
    font-size: $tag-new-font-size;
    font-weight: $tag-new-font-weight;
    color: $tag-new-color;
    background-color: $tag-new-bg;
    border: $tag-border-width solid $tag-new-border;
  }

  &--pack {
    font-size: $tag-pack-font-size;
    font-weight: $tag-pack-font-weight;
    color: $tag-pack-color;
    background-color: $tag-pack-bg;
    border: $tag-border-width solid $tag-pack-border;
  }
}