.c-global-reviews__list-item-perso {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacer;
  background-color: #fff;
  border-radius: 4px;
  border: none;

  &:first-child {
    align-items: center;
    text-align: center;
  }

  &:not(:last-child) {
    margin-right: $spacer*1.25;
  }
}

.c-review-text {
  color: #404040;
  font-size: $spacer*.75;
  margin-bottom: $spacer*2;
}

.c-global-reviews__item-customer-perso {
  color: #404040;
  margin-bottom: 0;
  font-size: $extra-small-font-size;
}

.c-avisverifies {
  background-color: #D1CFCF;
position: relative;
  padding: $spacer*3 0;
}

/*
.c-avisverifies::before {
  
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(-50vw + 50%);
  left: calc(-50vw + 50%);
}
*/

.c-avisverifies-bottomcontent {
  position: relative;
}

.c-avisverifies-allavislink {
  text-decoration: underline;
  color: $white;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  font-size: $small-font-size;

  &:hover {
    color: $white;
  }
}

.c-avisverifies__notationglobal {
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 8px;
}

.c-global-reviews__footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $spacer*1.5;

  @include media-breakpoint-mobile {
    flex-direction: column;
  }
}