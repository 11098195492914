


//state
.is-facetitem-hide{
  display: none;
}
//
@include media-breakpoint-desktop(){
  .c-alfacets__item{
    min-height: $facet-item-min-height;

    &.dropdown.open {
      .dropdown-menu{
        display: block;
      }
    }
  }

  .c-alfacet__panel-b.dropdown-menu{
    padding: 10px;
    min-width: 300px;
  }

  .s-alfacets-row{

    &.c-alfacets{
      display: flex;
      flex-wrap: nowrap;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: $facet-row-bg;
      //box-shadow: 0px 10px 4px rgb(0 0 0 / 30%);
      padding: 10px;
      margin-bottom: $spacer;


    }

    .c-alfacets__item{
      margin-right: $spacer;
      position: relative;

    }

    .c-alfacet__panel-btn{
      display: none;
    }

    .c-alfacet__panel{
    }
    //.c-alfacet__panel-b{
    //  position: absolute;
    //  top: 30px;
    //  left: 0;
    //  min-width: 230px;
    //  background: #fff;
    //  border: 1px solid red;
    //  padding: $spacer/2;
    //  z-index: 2;
    //}




  }
}
//.c-alfacet__panel--is-collapsed {
//
//  .c-alfacet__panel-b,
//  .c-alfacet__panel-f{
//    display: none;
//
//  }
//}

.c-alfacet__panel-h{
  position: relative;
}


//icons
.c-al-icon__dropdown{
  transition: all .2s ease-out;
  .show &{
    transform:rotate(180deg);
  }
}

.c-facet__panel-f{
  margin-top: 10px;
  @include button-outline-variant($primary);

  //btn btn-sm btn-primary
}

///collapse in offcanvas or left columns ?
.modal{
.ais-Panel--collapsed{
  .c-alfacet__panel-b{
  transition: all 200ms cubic-bezier(0.4, 0, 0.4, 1);
  height: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  }
}

.c-alfacet__panel-b{
//padding-top: 1.5rem;
//padding-bottom: 4rem;
height: auto;
visibility: visible;
opacity: 1;
transition: all 200ms cubic-bezier(0.4, 0, 0.4, 1);
}
}


//btn show facet
.c-btn-showfacetoffcanvas{
  margin-left: auto;
  margin-right: $spacer;
  @include media-breakpoint-desktop{
    display: none;
  }
  .c-btn-showfacetoffcanvas--offcanvas{
    display: inline-block;
  }
}

.c-al-top-filter{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}



