.c-label {
  position: relative;
  display: block;
  padding-left: $spacer;
  font-size: $small-font-size;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }

  &--success:before {
    background-color: $green;
  }

  &--danger:before {
    background-color: $red;
  }

  &--medium:before, &--orange:before {
    background-color: $orange;
  }

}