.c-pagination {
  display: flex;
  justify-content: center;
  margin: $spacer*5 auto;

  @include media-breakpoint-mobile {
    margin: $spacer*3 auto;
  }
}

.c-pagination__item {
  position: relative;

  &:not(:last-of-type) {
    margin-right: $pagination-margin-x;
  }

  @include media-breakpoint-mobile {
    &:not(:last-of-type) {
      margin-right: $pagination-margin-x-mobile;
    }
  }
}

.c-pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $pagination-width;
  height: $pagination-height;
  font-size: $pagination-font-size;
  font-weight: $pagination-font-weight;
  border-radius: $pagination-border-radius;
}

.c-pagination__link:hover {
  border-radius: $pagination-hover-border-radius;
}

.active.c-pagination__link {
  border-radius: $pagination-active-border-radius;
}

.c-pagination__icon {
  color: $pagination-icon-color;
}

// *** Underline effect ***

//.active.c-pagination__link:after {
//  position: absolute;
//  content: "";
//  background-color: $primary;
//  top: 100%;
//  left: 50%;
//  transform: translateX(-50%);
//  width: 20px;
//  height: 4px;
//}