.c-customer-header {
  padding: $spacer*2 0;
  text-align: center;
}

.c-customer-header__item-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// Sidebar column
.c-account-sidebar__block {
  margin-bottom: $spacer*.5;
}

.c-account-sidebar__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: $spacer $spacer*2;
  color: $black;
  background-color: $white;

  &:hover {
    background-color: $light;
  }

  &:focus {
    background-color: $gray-light;
  }

  &.is-active {
    font-weight: bold;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      content: "";
      background-color: $primary;
    }
  }
}

.c-account-sidebar__heading {
  @include media-breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }
}

.c-account-sidebar__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: $spacer;
  font-size: $h4-font-size;
  font-weight: $headings-font-weight;
  color: $white;
  text-transform: uppercase;
  background-color: $customer-avatar-color;
  border-radius: 50%;

  @include media-breakpoint-mobile {
    margin: 0 0 $spacer*.5 0;
  }
}

.c-account-sidebar__customer {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-mobile {
    flex-direction: row;
  }
}

// Main column
.c-account-landing {
  position: relative;
  z-index: 99;
  color: $white;
  height: 100%;
}

.c-account-landing__title {
  @extend .u-h1;
  max-width: 400px;
  margin-bottom: $spacer*1.5;
  color: $white;
}

.c-account-landing__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-account-landing__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $customer-landing-gradient;
}
