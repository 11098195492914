.c-coverpdt{
  //border: 1px solid #D9D9D9;
  background-color: $white;

  @include media-breakpoint-mobile {
    margin-bottom: $spacer;
  }

  .c-snap__item--fullw.glider-slide{
    flex: 1;
  }
}