.btn {
    letter-spacing: .2px;
    //color: #fff;
}

/* .btn-primary{
  background-image: linear-gradient(135deg, #{$primary} 0%, #ffac05 100%);
} */

.btn-primary,
.btn-secondary,
.btn-default {
    text-transform: uppercase;
    line-height: 20px;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
    color: $white;
}

.btn-default {
    background-color: $blue;
    color: $white;
    text-decoration: none;
    &:hover {
        color: $white;
        background-color: darken($blue, 5%);
    }
    &:focus {
          box-shadow: 0 0 0 .25rem rgba(54, 57, 72,0.25);
    }
}
