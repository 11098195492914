ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}
