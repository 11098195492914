/////HERO
.c-hero {
    position: relative;
    background: linear-gradient(180deg, $blue 30%, #fff 0);
}

.c-hero__content {
    background-color: transparent;
    padding: $spacer*2.5 $spacer*2.5 0 $spacer*2.5;
    position: absolute;
    right: 15%;
    bottom: 0;
    left: 60%;

    @media (max-width: 1050px) {
        position: inherit;
        padding: $spacer*2.5 0 0 0;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (max-width: 1870px) {
        right: 15%;
        bottom: 0;
        left: 55%;
    }

    @media (max-width: 1560px) {
        right: 10%;
        bottom: 0;
        left: 55%;
    }

    @media (max-width: 1340px) {
        right: 10%;
        bottom: 0;
        left: 50%;
    }

    @media (max-width: 1310px) {
        right: 10%;
        bottom: 0;
        left: 40%;
    }

    @media (max-width: 1150px) {
        right: 3%;
        bottom: 0;
        left: 30%;
    }
}

.c-hero-wrapper {
    @media (max-width: 1050px) {
        padding-right: 0;
        padding-left: 0;
    }
}


///
.c-cardtxt {
    padding-top: $spacer*2;
    padding-bottom: $spacer*2;
    border-top: 4px solid $black;

}

.c-cardtxt--lg {
    padding-top: $spacer;
    padding-bottom: $spacer;
}

//lazyload effect

[class].u-lazy-scale {
    opacity: 0;
    transform: scale(0.98);
}

[class].u-lazy-scale.lazyloaded {
    opacity: 1;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-delay: 0;
    transition-timing-function: ease-in cubic-bezier(.55, .055, .675, .19);
}

.c-rcktprem__surtitle {
    margin-bottom: 0;
}

@include media-breakpoint-desktop {
    .u-sticky\@lg {
        position: sticky;
        top: 20px;
    }
}

//image containier
.c-imgs__container {
    display: flex;
    justify-content: space-between;
}

@include media-breakpoint-desktop {
    .c-coverpdt {
        width: calc(100% - 130px);
        order: 1;

    }

    .c-pdt__thumbscontainer {
        display: flex;
        height: 40vw;
        max-height: 600px;
        flex-direction: column;
        overflow-y: auto;
        order: 0;
        margin-right: 30px;
        margin-bottom: $spacer*1.5;
        min-width: 100px;
        //transform: rotate(90deg);
        //max-width: 90%;
    }

    .c-snap--y\@lg {
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        /* Needed to work on iOS Safari */

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@include media-breakpoint-mobile {
    .c-breadcrumb {
        display: none;
    }

    .c-snap--x\@mobile {
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        /* Needed to work on iOS Safari */
    }

    .c-imgs__container {
        display: block;
    }

    .c-pdt__thumbs-list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    [class].c-pdt__thumbimg {
        //width: 64px;
        //height: 64px;
    }
}

.c-oney__btnlist {
    z-index: 2;
    margin-left: auto;

}

.c-oney__infoimg {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 0 auto;
}

.c-hero__media {
    padding-top: 2px;
}

// form customer

.custom-control-label em {
    text-transform: lowercase;
    color: #888;
}
