//header
$topheader-bg-color : $blue;


$form-label-font-size: $font-size-sm;
$form-label-font-family:       $headings-font-family;
$form-label-color:             $black;
$form-label-text-transform:         uppercase;
//
//$form-label-font-weight:       null !default;
//$form-label-font-style:         null !default;
$breadcrumb-divider:                quote("➔");
$breadcrumb-margin-bottom : $spacer*2.5;
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x: 0;


$display-line-height : 1;