//Header

.c-header {
    padding-bottom: $spacer*2;
    margin-bottom: $spacer*3;
    background-color: $header-bg;

    if($header-border-width) {
        border-bottom: solid $header-border-width $header-border-color;
    }

    @include media-breakpoint-mobile() {
        padding-bottom: 0;
    }
}

//Top Header

.c-header-top {
    padding-top: $spacer*.5;
    padding-bottom: $spacer*.5;
    font-size: $topheader-font-size;
    font-family: $topheader-font-family;
    color: $topheader-color;
    background-color: $topheader-bg-color;

    a {
        color: #c0bcbb;

        &:hover {
            color: $white;
        }
    }

}



//Header Core

.c-header__content {
    margin-top: $spacer*1.5;
    margin-bottom: $spacer*1.5;

    @include media-breakpoint-mobile() {
        flex-wrap: wrap;
        margin-top: $spacer;
        margin-bottom: $spacer;
    }
}

.c-header__logo {
    max-width: 100%;
    height: auto;

    @include media-breakpoint-mobile {
        max-width: 50%;
    }
}

.c-header__searchbar {
    flex: 1;
    margin: 0 $spacer*2;

    @include media-breakpoint-mobile() {
        flex: 0 0 100%;
        margin: $spacer 0 0;
    }
}

.c-header__shortcut-item {
    text-align: center;

    &:not(:first-child) {
        margin-left: $spacer*2;

        @include media-breakpoint-mobile {
            margin-left: $spacer*.75;
        }
    }
}

.c-header__shortcut-link {
    color: $header-shortcut-color;
    font-size: $header-shortcut-font-size;
    font-family: $header-shortcut-font-family;
    position: relative;
    

    &:hover {
        color: $header-shortcut-color-hover;
        text-decoration: none;
    }
}

.c-header__shortcut-link-label {
    display: block;
}

.c-header__shortcut-icon {
    color: $header-shortcut-icon-color;
    font-size: $header-shortcut-icon-size;
    line-height: 1;
}

.c-header__shortcut-link--customer {
    &-label {
        color: $header-shortcut-customer-color;
        font-size: $header-shortcut-customer-font-size;
    }
}

.blockcart,
.c-header__shortcut-icon--cart {
    text-align: center;
}

.c-header__shortcut-icon--cart {
    position: relative;
    padding-right: $header-shortcut-cart-count-padding-right;

    &-label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $header-shortcut-cart-count-width;
        height: $header-shortcut-cart-count-height;
        top: $header-shortcut-cart-count-top;
        right: $header-shortcut-cart-count-right;
        font-size: $header-shortcut-cart-count-font-size;
        font-weight: $header-shortcut-cart-count-font-weight;
        color: $header-shortcut-cart-count-color;
        background-color: $header-shortcut-cart-count-bg;
        border-radius: 50%;
    }
}

//Checkout

.c-header-checkout {
    padding: $spacer*2 0;
    margin-bottom: $spacer*3;
    border-bottom: solid 1px $gray-light;
}

//user 
.c-header__user-block .c-icon {
    position: relative;
    top: -5px;
}

.c-header__contact {

    .icon-contact,
    .group-label,
    .c-header__shortcut-link-label-2 {
        float: left;
        line-height: 1;
    }

    .c-header__shortcut-link-phone {
        font-size: 1rem;
        line-height: 1.1;
        color: $primary;
    }

    .c-header__shortcut-link-label-2 {
        clear: both;
    }
}
