//ps notification

.notifications-container:empty{
  display: none;
}
//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $stretched-link-z-index + 1;
    padding: $alert-padding-y * 1.25 $alert-padding-x;
  }
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.
.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border-color, $alert-success-color);
}
.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border-color, $alert-danger-color);
}
.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border-color, $alert-info-color);
}
.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border-color, $alert-warning-color);
}

.block-promo .promo-code .alert-danger {
  display: none;
}
