

.c-product-list {
  margin-top: 2rem;
}

.c-pdt-mini__title{
    min-height: $spacer*4;
}

.c-pagination {
    margin: $spacer*5 auto;
  
    @include media-breakpoint-mobile {
      margin: $spacer*3 auto;
    }
  }
  
  .c-pagination__item {
    position: relative;
    &:not(:last-of-type) {
      margin-right: $spacer;
    }
  
    @include media-breakpoint-mobile {
      &:not(:last-of-type) {
        margin-right: 0;
      }
    }
  }
  
  .c-pagination__link {
    display: flex;
    align-items: center;
  }
  
  .active.c-pagination__item:after {
    position: absolute;
    content: "";
    background-color: $primary;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 4px;
  }

.c-pdt-list__hr{
  border: 1px solid $secondary;
}

.c-pdt-list__composanttext{
  margin: $spacer*4 0 $spacer*4 0;
}

.c-pdt-list__textheading{
  font-size: $h1-font-size;
  font-weight: 700;
}

.c-pdt-list__textseo{
  margin-bottom: $spacer*2;
}

.c-price--old{
  font-family: $headings-font-family;
  font-size: 1rem;
  font-weight: normal;
  color: #595959;
  text-decoration: line-through;
}

///////////////////////////////////////

.ais-Pagination-item {
  position: relative;
  &:not(:last-of-type) {
    margin-right: $spacer;
  }

  @include media-breakpoint-mobile {
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }
}

.ais-Pagination-link  {
  display: flex;
  align-items: center;
}

.active.ais-Pagination-item:after {
  position: absolute;
  content: "";
  background-color: $secondary;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 4px;
}