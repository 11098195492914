.c-al-pdt-mini{
  display: flex;
  margin-top: 1rem;
  position: relative;

}

.c-al-pdt-mini__media{
  max-width: 70px;
  height: auto;
  margin-right: 1rem;
}
.c-al-pdt-mini__name.c-pdt-mini__name{
  display: block;
  margin-bottom: 0;
}
.c-al-pdt-mini__brand,
.c-al-pdt-mini__ref{
  font-size: $font-size-sm;
}


.c-alcathit,
.c-al-pdt-mini{
  mark{
    padding: 0;
    background-color: $highlightresult-bg-color;
  }
}
.c-al-noresults{
  display: none;
}

.has-no-results {
  .c-al-noresults{
    display: inline-block;
  }
}

