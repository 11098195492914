

@import '../../../../../modules/rocketalgolia/_dev/src/scss/settings/settings.colors';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/settings/settings.facets';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/settings/settings.spacing';


//@import '../../../../../modules/rocketalgolia/_dev/src/scss/generics/generics.algolia';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/vendors/algolia';


@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.alcathit';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.alfacets';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.alpdtsearch';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.alsearchform';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.btn-facet';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.current-refinement';

//@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.rangeslider';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.searchmodal';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.showmore';
@import '../../../../../modules/rocketalgolia/_dev/src/scss/components/components.statbtn';

[class].c-almodal-btn__close {
  width: 40px;
  height: 40px;
  margin-left: $spacer*1.5;
  border-radius: 50%;
  box-shadow: 0 8px 24px -8px rgba(0,0,0,0.32);
  background: #000;
}

[class].c-alsearchform__input {
  padding-left: $spacer*3;
  padding-right: $spacer*3;
  border-radius: $border-radius-lg;
}

[class].c-alsearchform__submit {
  left: $spacer;
  padding-left: $spacer;
}

[class].c-alsearchform__reset {
  right: $spacer;
  padding-right: $spacer;
}