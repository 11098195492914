.c-brand-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.c-brand_subtitle {
    text-align: center !important;
    font-size: $lead-font-size;
    margin-bottom: 0;
}

.c-brand_title {
    text-align: center !important;
    font-weight: 700;
    margin-bottom: $spacer*2;
}

.c-brand-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.c-brand-blockcontent {
    margin-right: $spacer*2;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.c-brand-blockcontent__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
}

.c-brand-blockcontent__text {
    font-size: $spacer*.875;
    text-align: center !important;
    text-transform: uppercase;
}

#manufacturer {
    li.brand {
        min-width: 200px;
        margin-bottom: $spacer*2;
    }

    .brand-name, .brand-img {
        display: flex;
        height: 98px;
        width: 98px;
        margin: auto;

        a {
            color: #555;
            margin: auto;
            font-size: .9rem;
            text-transform: uppercase;
            line-height: 1rem;
            font-weight: bold;
            text-align: center;
        }
    }
    
    .brand-products {
            text-align: center;
    }

    .brand-infos {
        display: none;
    }
}
