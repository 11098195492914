/* Columns
  ========================================================================== */


@if not $enable-grid-classes {

  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }
  .col,
  .col-auto {
    @extend %grid-column;
  }
  .col-auto {
    @include make-col-auto();
  }

  $col-gutter-width: $grid-gutter-width/2;

  $columns-base: 1, 2, 4, 5, 6, 9, 12;
  $columns-xs: 5, 9; //for checkout steps
  $columns-sm: 1, 3, 4, 5,6,8, 9,11;
  $columns-md: 3, 4, 6, 8, 11;
  $columns-lg: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11;

  @each $column-base in $columns-base {
    .col-#{$column-base} {
      @include make-col($column-base);
      padding-right: $col-gutter-width;
      padding-left: $col-gutter-width;
    }
  }

  @each $column-xs in $columns-xs {
    .col-xs-#{$column-xs} {
      @extend .col-#{$column-xs};
    }
  }

  @include media-breakpoint-up(sm) {
    @each $column-sm in $columns-sm {
      .col-sm-#{$column-sm} {
        @include make-col($column-sm);
      }
    }
  }
  @each $column-sm in $columns-sm {
    .col-sm-#{$column-sm} {
      @extend %grid-column;
    }
  }

  @include media-breakpoint-up(md) {
    @each $column-md in $columns-md {
      .col-md-#{$column-md} {
        @include make-col($column-md);

      }
    }
  }
  @each $column-md in $columns-md {
    .col-md-#{$column-md} {
      @extend %grid-column;
    }
  }

  @include media-breakpoint-up(lg) {
    @each $column-lg in $columns-lg {
      .col-lg-#{$column-lg} {
        @include make-col($column-lg);

      }
    }
  }
  @each $column-lg in $columns-lg {
    .col-lg-#{$column-lg} {
      @extend %grid-column;
    }
  }
}
