$search-color: $body-color !default;
$search-icon-size: 1.25rem !default;
$search-font-size: 1rem !default;
$search-font-family: $font-family-sans-serif !default;
$search-padding-x: $btn-padding-y - ($search-font-size - $font-size-base) !default;
$search-padding-y: null !default;
$search-padding-left: $search-padding-x !default;
$search-border-radius: $spacer*1.25 !default;
$search-border-color: transparent !default;
$search-bg-color: $gray-light !default;
$search-placeholder-color: $search-color !default;
