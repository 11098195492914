.c-pdt__title {
    font-size: 1.5rem;

}

//Pdt reviews
.c-review__top {
    margin-bottom: $spacer*1.5;
}

.c-review__link {
    text-decoration: underline;
    color: #4d4d4d;

    &:hover {
        color: #4d4d4d;
    }
}

//Pdt reass
.c-reassproduct {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: $spacer*.5 0;
}

.c-reassproduct__item {
    display: flex;
    align-items: center;
    padding: $spacer*.5 0;
    text-align: left;
}

//Desc
.c-pdt__sections .s-cms {
    overflow-y: auto;
}

.c-pdt__sections--collapse {
    border-top: 4px solid #000000;
    margin: $spacer*2 0 $spacer*4 0;
}

.c-collapse__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $h3-font-size;
    font-weight: 700;

    &[aria-expanded='true'] svg {
        transition: .5s;
        transform: rotate(180deg);
    }

    &[aria-expanded='false'] svg {
        transition: .5s;
        transform: rotate(0deg);
    }
}

.c-cat__heading {
    width: 70%;

    @include media-breakpoint-mobile {
        width: 100%;
    }
}

[class].c-btn-showfacetoffcanvas {
    display: block
}

.c-pdtlistsection {
    margin-top: $spacer*2;
}

.c-pdtlistsection__title {
    font-weight: 700;
    font-size: $h3-font-size;
}

.c-pdt-hr {
    border: 1px solid $black;
}

.c-pdt-brand {
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
    text-transform: uppercase;
    color: $black;
    margin-bottom: $spacer;

    & img {
        max-height: 55px;
        width: auto;
    }
}

.c-pdt-price {
    font-family: $headings-font-family;
    font-size: $h3-font-size;
    font-weight: 700;
}

.c-pdt-ref {
    color: #4D4D4D;
    font-size: $small-font-size;
}

//Thumbnails
[class].c-pdt__thumb {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.is-thumb-selected {
        border-width: 4px;
    }
}

.c-pdt__thumb-video {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    width: 40px;
    height: 40px;
    margin: calc((#{$thumb-width} - 40px) / 2) auto;
    font-size: 21px;
    background: linear-gradient(to bottom right, $primary, #FFAC05);
    border-radius: 50%;

    @include media-breakpoint-mobile {
        margin: 0 0 0 auto;
    }
}

//Thumbnails counter on mobile
.c-pdt__imgcounterwrap {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: $spacer*.75;
    width: 100%;
}

.c-pdt__imgcounter {
    display: inline-block;
    padding: $spacer*.25 $spacer*.5;
    font-size: $extra-small-font-size;
    color: $white;
    background: rgba(0, 0, 0, 0.35);
    border-radius: 24px;
}

.c-pdt__imgswiper {
    position: absolute;
    width: 32px;
    top: $spacer;
    right: $spacer;
    //opacity: .35;
    //transition: .2s;
    //animation: 1s 1s handShake;
    //
    //@keyframes handShake {
    //  20% {
    //    transform: translate(-3px) rotate(-10deg);
    //  }
    //  40% {
    //    transform: translate(3px) rotate(10deg);
    //  }
    //  60% {
    //    transform: translate(-3px) rotate(-10deg);
    //  }
    //  80% {
    //    transform: translate(3px) rotate(10deg);
    //  }
    //}
}

//Pdt description
.c-pdt__table {
    margin-bottom: $spacer*1.5;
    width: 100%;
}

.c-pdt__table-row {
    border-bottom: solid 1px #BFBFBF;
    font-size: $small-font-size;

    &.odd {
        background-color: #f0f0f0;
    }

    &:first-child {
        border-top: solid 1px #BFBFBF;
    }

    & th {
        text-transform: uppercase;
        font-weight: 700;
        color: $dark;
        font-size: 0.9em;
        padding-left: 10px;
    }

    & td {
        padding-right: 10px;
    }

    & th,
    & td {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
}

.c-pdt__variants {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $headings-font-family;

    & .form-control,
    & ul {
        max-width: 280px;
    }

    & ul {
        min-width: 280px;
    }
}

// tooltip //

[data-customTooltip] {
    cursor: pointer;
    position: relative;
}

[data-customTooltip]::after {
    background-color: $secondary;
    font-weight: normal;
    color: $primary;
    font-size: 14px;
    padding: 8px 12px;
    height: fit-content;
    width: fit-content;
    border-radius: 6px;
    position: absolute;
    text-align: center;
    bottom: -3px;
    left: 50%;
    content: attr(data-customTooltip);
    transform: translate(-50%, 110%) scale(0);
    transform-origin: top;
    transition: 0.14s;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
    z-index: 10;
}

[data-customTooltip]:hover:after {
    display: block;
    transform: translate(-50%, 110%) scale(1);
}


// Description

.product-description {
    .container {
        padding: 0;
        overflow: hidden;
    }

    h2 {
        font-size: 1.2rem;
        text-decoration: underline;
        margin-top: 1rem;
    }

    li::before {
        color: $primary;
        display: inline-block;
        margin-left: 5px;
        width: 20px;
        height: 20px;
        content: "\2713";

    }

    iframe {
        width: 100%;
    }
}

//Dispo-attributes

.c-dispo__item {
    color: $primary;
    text-decoration: underline;

    &:hover {
        color: inherit;
    }
}


.dispo-attributes,
.chechout-item {
    padding: 5px;
    border-bottom: 1px solid $gray-light;

    .dispo-img {
        width: 100px;
    }

    .product-name {
        font-weight: bold;
        font-size: 1.1em;
    }

    .product-quantity {
        float: left;
        margin-bottom: 5px;
    }

    .product-price {
        float: right;
        margin-bottom: 5px;
        font-weight: bold;

    }

    .dispo-list,
    .chechout-item-list {
        width: 100%;
        font-size: 0.8em;
        padding-left: 10px;
    }

    .name-group,
    .label {
        color: #555;
    }
}

.btn-cart {
    width: 90px;
    white-space: normal;
    font-size: 0.8rem;
    padding: 5px;
    text-transform: none !important;
}

// Guide des tailles
.c-taille__item {
    &:hover span {
        color: $primary;
    }

    .icon-info {
        position: relative;
        top: -3px;
        color: $primary;
    }
}

.table-taille.table {
    color: $blue;
    text-align: center;

    thead th {
        vertical-align: middle;
        background-color: $blue;
        color: $primary;
        border-bottom-width: 0;
    }

    th {
        vertical-align: middle;
    }

    td,
    th {
        padding: 0.4rem .75rem;
        border-color: $blue;
    }
}

.text-resa {
    text-align: center;

    &.alert-success {
        color: #155724;
    }

    a {
        font-weight: bold;
    }
}

#dispo-modal {
    .modal-body {
        flex: auto;
        display: flex;
        flex-direction: column;
    }

    .text-resa {
        margin-top: auto;
    }
}
