.c-adrckt__container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $black;
  overflow: hidden;

  @include media-breakpoint-mobile {
    min-height: 265px;
  }
}

.c-adrckt__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: $spacer*1.5;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.5), rgba(0,0,0,.4), rgba(0,0,0,0));

  @include media-breakpoint-mobile {
    align-items: flex-start;
  }
}

.c-adrckt__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
}