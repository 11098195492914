//Gauge

$width-star: 16px;
$margin-between-star: 5px;

.c-pdt-avis__gauge-star {
  width: (($width-star + $margin-between-star) * 5) - $margin-between-star;
  min-width: (($width-star + $margin-between-star) * 5) - $margin-between-star;
  height: 15px;
  background: url('../img/stars-black.svg') no-repeat 0 0;
  margin-right: 1rem;
}

@for $number from 1 through 5 {
  .c-pdt-avis__gauge--#{$number} {
    .c-pdt-avis__gauge-star {
      background-position: ((((($width-star + $margin-between-star) * 5) - $margin-between-star) - ($number * $width-star)) - ($number - 1) * $margin-between-star) 0;
    }
  }
}

.c-pdt-avis__gauge {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem*.5;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-pdt-avis__gauge-bar {
  position: relative;
  max-width: 380px;
  width: 100%;
  min-width: 100px;
  height: 10px;
  background: $gauge-empty-color;
  border-radius: 5px;

  @media screen and (max-width: 992px) {
    width: 130px;
  }

  &--active {
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    background: $gauge-color;
    border-radius: 5px;
  }
}


.c-pdt-avis__gauge-count {
  width: 20px;
  margin-left: .625*1rem;
}