.c-rcktnav__submenuwrap {
  //display: flex;
  //justify-content: space-between;
}

.s-menu--submenu_under{
.c-submenu{
  border: 1px solid $border-color;
}

.c-rcktnav__submenu {
  display: none;
}

.c-submenu__container{
// padding-top: 40px;
// padding-bottom: 40px;
}


.c-rcktnav__submenu {
  position: absolute;
  //margin-top: $spacer;
  //opacity: 0;
  width: 100%;
  left: 0;
  // top: $menu-submenu-top;
  z-index: -1;
  //margin-left: 50%;
  //transform: translateX(-50%);
  background-color: #fff;
  //padding: $submenu-padding;
}

 .c-rcktnav-active {
  .c-rcktnav__submenu {
    display: block;
    z-index: $submenu-zindex;
    animation: fadeIn .2s;
    //border-top: 1px solid transparent;
    //border-bottom: 1px solid transparent;
    overflow-y: auto;
    max-height: 80vh;
  }
}
}
.s-menu-fixed{

  .c-rcktnav-active {
    .c-rcktnav__submenu {
      display: block;
      z-index: $submenu-zindex;
      position: fixed;
      top: 0;
      box-shadow: 0 8px 24px -8px rgba(0,0,0,0.32);
      border-radius: 0 0 16px 16px;
    }
  }

}



.c-rcktnav-submenu__container{

}
.submenu__grouplink-item a{
  color: $secondary;
  &:hover{
    text-decoration: $text-decoration-primary;
  }
}

.c-rcktnav-btn__close{
  position: absolute;
  right: 40px;
  top: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 8px 24px -8px rgba(0,0,0,0.32);
  background: #1864AB linear-gradient(134deg, #1971C1 0%, #1864AB 100%);
  z-index: 1;
}

@include media-breakpoint-down(xs){
  .c-rcktnav__submenuwrap {
    flex-direction: column;
  }
}