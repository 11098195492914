.u-bg-blue {
    background-color: $blue;
}

.u-txt-right {
    text-align: right;
}

.u-txt-center {
    text-align: center;
}

.col-xs-12, .col-lg-12 {
    @extend .col-12;
}

@media (min-width:768px) {
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-5,
    .col-md-7,
    .col-md-6 {
        padding-right: 15px;
        padding-left: 15px;
    }
}
