.c-blockseo{
    background-color: #eee;
    padding: $spacer*4 0;
    @include media-breakpoint-mobile () {
        padding: $spacer*2 0;
    }
}

.c-blockseo-content hr{
    border: 2px solid $secondary;
}

.c-blockseo_img{
    width: 80%;
    height: auto;
}

.c-blockseo_text{
    line-height: 28px;
}