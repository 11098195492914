$font-family-sans-serif:     "Montserrat", -apple-system, blinkmacsystemfont, "Segoe UI", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family : "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base:              1rem;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.7;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;


$display1-size:               3.5rem;

$display1-weight:             700;
//$display-line-height:         $headings-line-height !default;

$lead-font-size:              $font-size-base * 1.125 ;
$lead-font-weight:            null;


//button and input
$input-btn-font-family : $headings-font-family;
$input-btn-line-height: 1.375;
$input-btn-padding-y:      .5rem;

$btn-font-size:               0.875rem;
$btn-font-weight:             700;
$btn-white-space:             nowrap;
$btn-padding-y:               0.625rem; // 10px
$btn-padding-x:               1.875rem;
$btn-border-width: 0;

$btn-padding-y-lg:            0.875rem;
$btn-padding-x-lg:            1.5rem;
$btn-font-size-lg: $font-size-base;

// Breadcrumbs

$breadcrumb-padding-x:              0;
$breadcrumb-margin-bottom:          0;
$breadcrumb-active-color:           $black;

$gray-light: #ddd;
$panel-border-color: $gray-light;

$tooltip-bg :                       $secondary;
$tooltip-color:                     $primary;

@import "../../../../classic-rocket/_dev/css/settings/settings.bootstrap";
