.c-reass-o-wrapper__responsive{
    @include media-breakpoint-mobile () {
    padding-right: 0;
    padding-left: 0;
    }
}

.c-reass{
    background-color: $white;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.c-reass::-webkit-scrollbar {
    display: none;
  }

.c-reass-element{
    display: flex;
    padding: $spacer*2 $spacer*4;
    justify-content: space-between;
    width: 100%;
    @include media-breakpoint-mobile () {
        padding-left: 0;
    }
}

.c-reass-content{
    display: flex;
    align-items: center;
    text-align: left;
    text-decoration: none;
    color: $black;
    min-width: $spacer*18;
    .c-icon {
        color: $orange;
    }
}

.c-reass-text{
    margin: 0;
}

.c-modal-title{
    margin-bottom: 0;
    align-self: center;
}

.c-modal-end{
    border: 0;
    background: none;
}

.c-modal-cross{
    font-size: $spacer*2;
}