// ***** INPUT *****

//Sizes
$input-color-width: 16px;
$input-color-height: 16px;

//Border
$input-color-border-width: 0;


// ***** HOVER *****

//Sizes
$input-color-hover-width: 24px;
$input-color-hover-height: 24px;

//Border
$input-color-hover-border-color: $secondary;
$input-color-hover-border-width: 2px;

//Opacity
$input-color-hover-opacity: 1;

// ***** SELECTED *****

//Sizes
$input-color-selected-width: 24px;
$input-color-selected-height: 24px;

//Border
$input-color-selected-border-color: $secondary;
$input-color-selected-border-width: 2px;

@import '../../../../classic-rocket/_dev/css/settings/settings.input-color';