#stores {
    .store-card {
        margin-bottom: $spacer*1.5;
    }

    .card-body {
        background-color: #d1cfcf;
        color: $blue;
        padding: $spacer $spacer 0 $spacer;
        overflow: hidden;
    }

    .carousel-item {
        height: 220px;
        overflow-y: auto;

        .table td,
        .table th {
            padding: 0.3rem .75rem;
        }
    }

    .carousel-indicators {
        display: flex;
        position: inherit;
        left: inherit;
        right: inherit;
        top: inherit;
        background: none;
        clear: both;
        padding: 0;
        margin: 1rem -$spacer 0 -$spacer;
        z-index: 1;

        li {
            height: auto;
            text-align: center;
            text-indent: inherit;
            background-color: $blue;
            padding-bottom: 3px;
            margin-left: 1px;
            margin-right: 1px;
            border-bottom: none;
            flex-basis: 0;
            flex-grow: 1;
            opacity: 1;

            &.active {
                background-color: $orange;
            }
        }
    }


}
