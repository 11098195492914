.c-rcktnav__modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.c-rcktnav__modal-bottom {
    margin-top: auto;
    padding: 10px;
}

@include media-breakpoint-mobile {
    .s-menu--offcanvas\@mob {


        //styling
        .c-rcktnav__itemlink {
            color: $body-color;
            width: 100%;
            //max-width: 400px;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

        }

        .c-rcktnav-active {
            .c-rcktnav__itemlink {
                display: none;

            }
        }

        .c-rcktnav__item.c-rcktnav-active {
            padding: 0;
        }

        .c-rckt-grouplink {
            margin-bottom: $spacer*1.5;
        }


        //layout

        &.c-rcktnav__list {
            flex-direction: column;
            width: 100%;
            justify-content: unset;
            align-items: unset;
        }

        .c-rcktnav__item {
            margin: 0;
            padding: 0;
            border-bottom: 1px solid $border-color;
        }

        .c-rcktnav-icon__dropdown {
            display: none;
        }

        .c-rcktnav-btn__close {
            display: none;
        }

        //submenu
        .c-rcktnav__submenu {
            display: none;
        }

        .c-rcktnav-active {
            .c-rcktnav__submenu {
                display: flex;
                flex-direction: column;
                height: 100%;
                left: 0;
                position: fixed;
                top: 56px;
                z-index: 2;
                background-color: #fff;
                overflow-y: auto;
            }

            .c-submenu__container {
                height: 100%;
                background-color: #fff;

            }
        }


        //custom
        .c-rcktnav__item.c-rcktnav__item--alt {
            margin-left: 0;
        }

        //current nav
        .c-rcktnav-navsubmenu, .u-d-mobilexs {
            display: flex;
            align-items: center;
            //border-bottom: 1px solid $border-color;
            //margin-bottom: $spacer;
            font-weight: $font-weight-bold;
            background-color: $secondary;
            color: #fff;

            a,.btn {
                color: #fff;
            }


        }

        .c-rcktnav-navsubmenu__btn.btn {
            //padding: 5px;
            border-right: 1px solid #fff;
            border-radius: 0;
            margin-right: $spacer;
            padding: 20px 25px;
        }

        .c-submenu__inner .col-tab {
            background-color: transparent;
            .nav-tabs {
                border-bottom-width: 0px;
            }
            
        }
    }
}
