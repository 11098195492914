.modal-header--secondary {
  display: inline; // fix Safari
  padding: $spacer*2;
  background-color: $secondary;
  color: $white;
  border: none;
}

.btn-close {
    margin-left: auto;
}