/* Offsets
  ========================================================================== */

@if not $enable-grid-classes {

  .offset {
    //@extend %grid-column;
    position: relative;
    width: 100%;
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }

  $offsets: 0;
  $offsets-lg: 1, 2;

  @each $offset in $offsets {
    .offset-#{$offset} {
      @include make-col-offset($offset);
      position: relative;
      width: 100%;
      padding-right: $grid-gutter-width / 2;
      padding-left: $grid-gutter-width / 2;
    }
  }

  @include media-breakpoint-up(lg) {
    @each $offset-lg in $offsets-lg {
      .offset-lg-#{$offset-lg} {
        @include make-col-offset($offset-lg);
        position: relative;
        width: 100%;
        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
      }
    }
  }
}
