.modal.fade .modal-dialog__offcanvas {
  position: absolute;
  width: $offcanvas-width;
  height: 100%;
  margin: 0;
  top: 0;
  transform: translateX(-100%);
  transition: transform .3s ease-out;

  @include media-breakpoint-mobile {
    width: 100%;
  }
}

.modal.show .modal-dialog__offcanvas {
  transform: translateX(0);
}

.modal.fade .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
  right: 0;
  transform: translateX(100%);
}

.modal.show .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
  transform: translateX(0);
}

.modal-dialog__offcanvas .modal-content {
  height: inherit;
  border-width: 0;
  border-radius: 0;
}

.modal-dialog__offcanvas .modal-header {
  display: flex;
  align-items: flex-start;
  border-radius: 0;
}

.modal-dialog__offcanvas .modal-body {
  overflow-y: auto;
  height: inherit;
}

.modal-dialog__offcanvas .modal-footer {
  border: none;
  @include media-breakpoint-mobile {
    display: flex;
    justify-content: center;
  }
}

//

.modal-dialog__offcanvas .modal-header--primary {
  display: inline; // fix Safari
  padding: $spacer*2;
  background-color: $primary;
  color: $white;
  border: none;
}

.modal-dialog__offcanvas .modal-header--default {
  display: inline; // fix Safari
  padding: $spacer*2;
  background-color: $blue;
  color: #e85004;
  border: none;
}

.modal-dialog__offcanvas .modal-btn__close {
  color: $white;
  opacity: 1;
  background: none;
  border: none;
}

// fix Safari
.modal-dialog__offcanvas .modal-header--primary .modal-btn__close, .modal-dialog__offcanvas .modal-header--default  {
  margin-bottom: auto;
}

/////

.c-modal__btn-link--white {
  color: $white;

  &:hover {
    color: $white;
    text-decoration: underline;
  }

  & .c-icon {
    vertical-align: text-bottom;
  }
}