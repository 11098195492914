/* Wrapper and container
  ========================================================================== */

/**
* Page-level constraining and wrapping elements.
*/

@if $enable-grid-classes {

  [class].o-wrapper {
    width: 100%;
    max-width: 100%;

    @extend .container;
  }

} @else {

  .container {
    @include make-container();
    @media (min-width: 1500px) {
      max-width: 1440px;
    }
    // Version 4.0
    //@include make-container-max-widths();
  }

  // 100% wide container at all breakpoints
  .container-fluid {
    @include make-container();
  }
  .o-wrapper {
    @extend .container;
    @media (min-width: 1500px) {
      max-width: 1440px;
    }
  }
}

.o-wrapper--fullbleed {
  width: 100vw;
  max-width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 1440px) {
    margin-left: calc((-100vw / 2 + 1440px / 2) - 15px);
    margin-right: calc((-100vw / 2 + 1440px / 2) - 15px);
  }
}