.c-addcartsticky{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $addcartmob-z-index;
  background-color: $addcartmob-bg-color;
  padding-top: $addcartmob-padding-y;
  padding-bottom: $addcartmob-padding-y;

  opacity: 0;
  transition-property: opacity, transform;
  transform: translateY(100%);
  transition-duration: 0.3s;
  transition-delay: 0;
  transition-timing-function: ease-in cubic-bezier(.55, .055, .675, .19);
  box-shadow: 0 0 10px 0 rgba(.45 ,.77 ,.133,.5);
  &.is-addcartsticky-show{
    transform: translateY(0);
    opacity: 1;
  }
}
.c-addcartsticky__row{
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
}
.c-addcartsticky__img{
  margin-right: $spacer/2;
  border: 1px solid $body-color;
  @media (max-width: 411.98px) {
    display: none;
  }

}

.c-addcartsticky__title{
  font-size: 14px;
  margin-bottom: $spacer/4;
  font-weight: bold;
}

.c-addcartsticky__readmore{
  font-size: 12px;
  color: $body-color;
  text-decoration: underline;

}
