.c-footer {
    padding: 0;
}

.c-footer__content {
    background-color: $secondary;
}

.c-footer__content, .c-footer__content .u-txt-white {
    color: #c0bcbb;
}

.c-footer hr {
    color: $white;
    border: 1px solid $orange;
}

.c-footer-newsletter__title {
    display: flex;
    font-size: $lead-font-size;
    text-transform: uppercase;
}

.c-footer__newsletter {
    background-color: $secondary;
    margin-bottom: 0;
    padding: 0 0 $spacer*2 0;
    button {
        border-radius: 0 30px 30px 0;
    }
}

.c-footer-newsletter__input {
    @include media-breakpoint-mobile {
        flex-direction: column;
    }
}

.c-footer-newsletter__text {
    align-self: center;
    font-size: $lead-font-size;
    font-weight: 400;
}

.c-footer-newsletter__emailtext {
    font-size: $spacer*.875;
}

.c-footer-blocknewsletter__input {
    color: $gray-400;
    background-color: transparent;
    border: 1px solid $orange;
    border-radius: 30px 0 0 30px;

    @include media-breakpoint-mobile {
        margin-bottom: $spacer;
    }

    &:focus {
        color: #c0bcbb;
        border-color: $orange;
        background-color: transparent;
    }
}

.c-footer-blocknewsletter__input::placeholder {
    color: #c0bcbb;
}

.c-footer-newsletter__btn {
    @include media-breakpoint-mobile {
        width: 100%;
    }
}

.c-footer-catlink__section {
    margin-bottom: $spacer*5;

    @include media-breakpoint-mobile {
        margin-bottom: 0;
    }
}

.c-footer-catlink__title {
    display: flex;
    justify-content: space-between;
    color: #c0bcbb;
    font-size: $lead-font-size;
    text-transform: uppercase;
    margin-top: $spacer*2;

    @include media-breakpoint-mobile {
        margin-top: 0;
    }
}

.c-footer-catlink__linksection {
    display: flex;
    flex-direction: column;
}

.c-footer-linkcollapse {
    @include media-breakpoint-desktop {
        pointer-events: none;
        color: currentColor;
        display: block;
        /* For IE11/ MS Edge bug */
        text-decoration: none;
    }
}

.c-footer-catlink__link {
    text-decoration: none;
    color: #c0bcbb;
    margin-top: $spacer;

    &:hover {
        color: $orange;
    }
}

.c-footer-noticesection {
    margin-bottom: $spacer*4;

    @include media-breakpoint-mobile {
        display: flex;
        margin-top: $spacer*2;
        margin-bottom: $spacer*2;
    }
}

.c-footer-payementsection {
    display: flex;
    justify-content: center;
    margin-bottom: $spacer*4;
    flex-wrap: wrap;
}

.c-footer-payementsection__img {
    margin-right: $spacer*.5;
}

.c-footer-copyrightlink {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacer*4;

    @include media-breakpoint-mobile {
        justify-content: flex-start;
    }
}
