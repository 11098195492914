.c-faq{
    position: relative;
    padding: $spacer*4 0 $spacer*4 0;
  }
  
  .c-faq::before {
      content: "";
      background-color: #f2efec;
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(-50vw - -50%);
      left: calc(-50vw - -50%);
      margin-left: 10px;
      margin-right: 10px;
  }
  
  .c-faq-content{
    margin-left: $spacer*20;
    margin-right: $spacer*20;
    position: relative;
    @include media-breakpoint-mobile {
      margin-left: $spacer*4;
      margin-right: $spacer*4;
    }
  }
  
  .c-faq-title{
    font-size: $h1-font-size;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .c-faq-hrgeneral{
    border: 4px solid $black;
  }
  
  .c-faq__question{
    color: $secondary;
    text-decoration: none;
    font-size: $h3-font-size;
    font-weight: 700;
  
    &[aria-expanded='true'] svg {
      transition: .5s;
      transform: rotate(180deg);
    }
  
      &[aria-expanded='false'] svg {
      transition: .5s;
      transform: rotate(0deg);
    }
  }
  
  .c-faq-question__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }