.c-hpservices {
    margin: 0 0 30px 0;
}

.c-hpservices-img {
    padding: 0;
    margin-top: $spacer*4;
    background-color: $blue;

    @media (max-width: 1050px) {
        margin-top: 0;
    }

    @media (min-width: 1000px) {
        img {
            border-radius: 0 50% 50% 0;
        }
    }
}

.c-hpservices-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $blue;
    color: white;
    padding: $spacer $spacer;
    margin-top: $spacer*4;

    @media (max-width: 1050px) {
        margin-top: 0;
    }
}

.c-hpservices-content hr {
    border: 2px solid $white;
    width: 100%;
}

.c-hpservices-servicesav__title {
    font-size: $lead-font-size;
    text-transform: uppercase;
    margin-bottom: 0;
}



.c-hpservices-servicesav__subtitle {
    font-weight: 700;
    margin-bottom: 0;
}

.c-hpservices-servicesav__text {
    line-height: 28px;
}

.c-blockcard-components {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.c-blockcard {
    flex: 1 0 48%;
    margin: 10px;
}

.c-blockcard-title {
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: $spacer;

    @media (max-width: 1050px) {
        font-size: $spacer*1.25;
    }
}

.c-blockcard-link {
    text-decoration: none;
    color: $primary;
    font-weight: 700;
    text-transform: uppercase;
}

.c-blockcard hr {
    border: 1px solid $black;
}
