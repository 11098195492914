// products thumbs

.c-pdt__thumbs {
  width: calc(#{$thumb-wrap-width} - #{$btn-arrow-width*2});
  margin: $thumb-wrap-margin;
}

.c-pdt__thumb {
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-border-radius;
  padding: $thumb-padding;
  width: $thumb-min-width-glider;
  cursor: pointer;
  overflow: hidden;

  &.glider-slide {
    min-width: $thumb-min-width-glider;
  }

  &[class].c-snap__item {
    margin: $thumb-margin-y $thumb-margin-x;

    &:first-of-type {
      margin-left: $thumb-extremist-margin-x;
    }
    &:last-of-type {
      margin-right: $thumb-extremist-margin-x;
    }
  }

  //thumbnail selected
  &.is-thumb-selected {
    border-color: $thumb-active-border-color;
    cursor: auto;
    box-shadow: $thumb-active-box-shadow;
  }

  //thumbnails not selected
  &:not(.is-thumb-selected) {
    opacity: $thumb-inactive-opacity;
  }
}

.c-pdt__thumbimg {
  width: $thumb-width;
  height: auto;
}

.c-pdt__video {
  display: block;
  max-width: 100%;
}

//buton for collapse
.c-pdt__sections--collapse {
  border-top: 1px solid $pdt-btn-collapse-border-color;
}

/* see _settings.product.scss */
.c-pdt__title {
  @include font-size($pdt-name-font-size);
  font-weight: $pdt-name-font-weight;
  font-family: $pdt-name-font-family;
  color: $pdt-name-color;
  text-transform: $pdt-name-text-transform;
  text-decoration: $pdt-name-text-decoration;
  letter-spacing: $pdt-name-letter-spacing;
  line-height: $pdt-name-line-height;
  margin-bottom: $pdt-name-margin-bottom;

}

