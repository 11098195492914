/* Columns
  ========================================================================== */

@if not $enable-grid-classes {

  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.o-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}

.o-layout--center {
  &-x {
    justify-content: center;
  }
  &-y {
    align-items: center;
  }
}

.o-layout--start {
  justify-content: flex-start;
}

.o-layout--end {
  justify-content: flex-end;
}
