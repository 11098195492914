.c-faqpage-title{
    font-weight: 700;
    font-size: $h1-font-size;
}

.c-faqpage-subtitle{
    font-size: h4-font-size;
}

.c-faqpage-cat{
    flex-direction: column;
    margin-bottom: $spacer*4;
}

.c-faqpage-cat_link{
    text-decoration: underline;
    font-weight: 700;
    color: $secondary;
}

.c-faqpage-question{
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    color: $secondary;
    margin-bottom: $spacer*2;
}

.c-faqpage-titlepart{
    font-size: $h3-font-size;
    font-weight: 700;
}