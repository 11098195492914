//Global site reviews
.c-global-reviews {
  display: flex;
  margin-bottom: 1rem*2.5;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.c-global-reviews__list {
  display: flex;
  justify-content: space-around;

  &--slider {
    max-width: 80%;
  }
}

.c-global-reviews__list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem*1.25;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  border: none;
  @media screen and (max-width: 992px) {
    margin: 0 1rem 1rem;
  }
}

.c-global-reviews__item--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.c-global-reviews__item-customer {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;;
}

.c-global-reviews__item-date {
  font-size: 1rem*.875;
  margin-bottom: 0;
}