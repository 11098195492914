.h-stores {
    .dropdown {
        text-align: center;
    }
}

#carouselStores {
    max-width: 550px;
    margin: 30px auto 15px;
    
    .carousel-caption {
        background-color: #cfccc2;
        color: $blue;
        border-radius: 150px;
    }
}