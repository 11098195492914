@import "../settings/settings.menu";

@import "../../../../../modules/rocketmegamenu/_dev/src/scss/settings/settings.settings";
@import "../../../../../modules/rocketmegamenu/_dev/src/scss/settings/settings.nav";

@import "../../../../../modules/rocketmegamenu/_dev/src/scss/objects/objects.column";


@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.grouplink";
@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.nav";
@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.navmodal";
//@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.navtabs";
@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.submenu";
@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.offcanvasmobile";
//@import "../../../../../modules/rocketmegamenu/_dev/src/scss/components/components.to_refacto";


#wrapper {
    position: relative;
}

.menu-open #wrapper::after {
    content: "";
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    width: 100vw;
    z-index: 2;
}

#_desktop_mmenu {
    position: relative;

    .c-rcktnav__item {
        flex: auto;
    }
        
    .margin-submenu .c-submenu__inner{
        padding: 20px 30px;
    }
}

.c-rcktnav__list {
    justify-content: left;
    align-items: center;
    background-color: #5a5d6c;
}

.c-rcktnav__itemlink {
    padding: 0.8rem 1.2rem;
    border-right: 1px solid $blue;
    display: block;

    &:hover,
    .c-rcktnav-active & {
        background-color: $orange;
        color: $white;
    }

    .last-item & {
        background-color: $orange;
        color: $white;

        &:hover {
            background-color: #c0bcbb;
            color: $blue;
        }
    }
}



.c-rcktnav__item {
    &:not(:first-child) {
        margin-left: 0;
    }

    &:last-child .c-rcktnav__itemlink {
        border-right-width: 0px;
    }
}

.c-rcktnav__item.c-rcktnav__item--alt {
    margin-left: auto;

}

.c-submenu__inner .col-tab {
    padding: 0;
    background-color: #cfccc2;
}


[class].c-navtabs--y {
    flex-direction: column;
    position: sticky;
    top: 10px;
}

.c-navtabs__sticky {
    position: sticky;
    top: 0;
}

.c-tab-content {
    width: 100%;
    //padding: 20px;
    
    &.tab-content > .active {
        display: flex;
        flex-wrap: wrap;
        
    }
    
    [class^="col-"] {
        padding: 20px;
    }
}

@include media-breakpoint-mobile {}

@include media-breakpoint-down(xs) {
    [class].c-navtabs--y {
        min-width: auto;
        width: 100%;
    }

    .c-tab-content {
        width: 100%;


        &.tab-content > .active {
            position: fixed;
            overflow-y: scroll;
            max-height: 100%;
            top: 0;
            left: 0;
            width: 100%;
            border-radius: 16px;
            padding: 1.5*$spacer;
            z-index: 2;
        }
    }
}


.c-navtabs--y {
    .c-rckt-tab__link {
        font-weight: $font-weight-bold;
        color: $blue;
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #b5af9d;

        .c-rckt-tab__img {
            display: inline-block;
            width: 46px;
            text-align: center;
            margin-right: $spacer;

        }

        .c-rckt-tab__icon--active {
            display: none;
        }

        &.active {
            color: $primary;
            background: $white;

            .c-rckt-tab__icon {
                display: none;
            }

            .c-rckt-tab__icon--active {
                display: inline-block;
            }
        }
    }
}


.c-rckt-subm__link,
.c-rckt-manu__link {
    color: #000;
    display: block;
    padding: 5px 0;
    text-transform: capitalize;

    &:hover {
        color: $primary;
    }
}

.c-rckt-manu__item {
    display: block;
    width: 100%;
}


.c-rckt-subm__link--lg {
    color: $primary;
    display: block;
    font-weight: $font-weight-bold;
}

.c-rckt-grouplink__content {
    a {
        font-size: $font-size-sm;
        line-height: 1.75;
        color: $gray-800;

        &:hover {
            text-decoration: underline;
        }
    }
}

.c-rckt-grouplink,
.c-rckt-subm__link--lg {
    &:not(:last-child) {
        margin-bottom: 1.5*$spacer;
    }
}

.c-rckt-grouplink > .c-rckt-subm__link--lg {
    margin-bottom: $spacer*.5;

}

.c-modal-rcktnavbar {
    .modal-header {
        border-radius: 0;
        border: none;
        justify-content: flex-end;
    }

    .modal-dialog {
        max-width: 100%;
        margin: 0;

        @include media-breakpoint-down(xs) {
            height: 100vh;
            margin: 0;
        }
    }

    .modal-content {
        border-radius: 0;
        border: none;
        border-bottom-left-radius: $spacer;
        border-bottom-right-radius: $spacer;
        box-shadow: 0 8px 24px -8px rgba(0, 0, 0, 0.32);

    }

    .modal-body {
        padding-bottom: $spacer*4;
    }
}


@include media-breakpoint-mobile {
    .s-menu--offcanvas\@mob {


        &.c-rcktnav__list {
            background-color: transparent;
        }


        .c-rcktnav__itemlink {
            border-right-width: 0px;
        }

        .c-rcktnav-active .c-rcktnav__submenu {
            width: 100%;
        }

        .c-rcktnav__submenuwrap {
            padding: 0;

            & > .row {
                margin: 0;
            }
        }
    }

    .c-tab-content.tab-content > .active {
        background-color: #fff;
        top: 57px;
        border-radius: 0;
        padding: 0;

        .row {
            margin: 0;
        }
    }

    .c-rcktnav__submenuwrap,
    .c-tab-content.tab-content > .active {
        [class*="col-"] {
            padding: 0;
        }

    }

    .c-navtabs--y .c-rckt-tab__link {
        color: black;
        border-bottom: 1px solid #ccc;
        padding: .8rem 1.2rem;

        &:hover {
            color: #fff;
            background-color: $primary;
        }

        .c-rcktnav-icon__right {
            margin-left: auto;
        }
    }

    .c-rckt-grouplink {
        margin-bottom: 0 !important;

        .c-rckt-subm__link--lg,
        .c-rckt-subm__link--lg:hover {
            background-color: #cfccc2;
            color: $blue;
            padding: .8rem 1.2rem;
            margin-bottom: 0 !important;
        }
    }

    .c-rckt-subm__link,
    .c-rckt-manu__link {
        padding: .8rem 1.2rem;
        font-weight: 700;
        border-bottom: 1px solid #ccc;

        &:hover {
            background-color: $primary;
            color: #fff;
        }

        &:last-item() {
            border-bottom-width: 0px;
        }
    }


}
