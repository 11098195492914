.c-header {
    background-color: $header-bg;
    margin-bottom: 0;
    padding-bottom: 0;
}

.c-header__content {
    margin-top: $spacer*2;
    margin-bottom: $spacer*2.3;
    
    @include media-breakpoint-mobile {
        margin-top: $spacer*0.5;
        margin-bottom: 0;
        padding-bottom: $spacer;
    }
}

.c-search .btn {
    color: $primary;
}

.c-search .form-control {
    color: #ddd;
    &:focus {
        background-color: transparent;
        border-color: $primary;
        color: #333;

    }
}

.c-banner-text {
    display: flex;
    align-items: center;
}

.c-icon-tel {
    margin-left: $spacer;
}

.c-numtel {
    @include media-breakpoint-mobile () {
        display: none;
    }
}

.c-header__shortcut-link--customer-label {
    color: $primary;
}
