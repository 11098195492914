@import "../../../../classic-rocket/_dev/css/components/components.breadcrumb";

#breacrumb {
    //background-color: #eee;
    padding-top: $spacer*1.25;
    padding-bottom: $spacer*1.25;
    //margin-bottom: $spacer*2;
}

.c-breadcrumb {
    //text-transform: uppercase;
    font-family: $headings-font-family;
    margin-bottom: 0;
    

    & a {
        color: #4d4d4d;
        &:hover {
            color: $primary;
        }
    }
}

#authentication .o-wrapper nav .c-breadcrumb {
    display: none;
}

#password .o-wrapper nav .c-breadcrumb {
    display: none;
}

.breadcrumb-item + .breadcrumb-item:before, .breadcrumb-item.active {
    color: #4d4d4d;
}
