//Review on product
.c-review__top {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.c-review__link {
  font-size: 1rem*.875;
  margin-left: 1rem*.5;
  text-decoration: underline;
  color: #000;
}