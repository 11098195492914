.c-pdt-mini {
  margin-bottom: $pm-margin-bottom;
}

//.c-pdt-list .c-pdt-mini {
//  margin-bottom: $pm-margin-bottom;
//}

.c-pdt-mini__card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $pm-card-bg-color;
  border-radius: $pm-card-radius;
  border: $pm-card-border;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  @if $enable-pm-hover-shadow {
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: $pm-card-radius;
      box-shadow: $pm-card-shadow;
      opacity: 0;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

      .is-hover & {
        opacity: 1;
        z-index: 0;
      }
    }

    &:hover {
      //transform: scale(1.02, 1.02);
    }
  }
}

.c-pdt-mini__name {
  @include font-size($pm-name-font-size);
  font-weight: $pm-name-font-weight;
  font-family: $pm-name-font-family;
  color: $pm-name-color;
    text-align: left;
    text-transform: $pm-name-text-transform;
  text-decoration: $pm-name-text-decoration;
  letter-spacing: $pm-name-letter-spacing;
  line-height: $pm-name-line-height;
  display: inline-block;
  margin-bottom: $pm-name-margin-bottom;

  &:hover {
    color: $pm-name-color-hover;
    text-transform: $pm-name-text-transform-hover;

  }
}

.c-pdt-mini__brand {
  @include font-size($pm-brand-font-size);
  font-weight: $pm-brand-font-weight;
  font-family: $pm-brand-font-family;
  color: $pm-brand-color;
  text-transform: $pm-brand-text-transform;
  text-decoration: $pm-brand-text-decoration;
  letter-spacing: $pm-brand-letter-spacing;
  line-height: $pm-brand-line-height;
  margin-bottom: $pm-brand-margin-bottom;
}

.c-pdt-mini__thumb {
  position: relative;
  order: 1;
  border: $pm-thumb-border;
  border-radius: $pm-thumb-border-radius;
  background-color: $pm-thumb-bg-color;
  padding: $pm-thumb-padding-y $pm-thumb-padding-x;
  margin-bottom: $pm-thumb-margin-bottom;
}

.c-pdt-mini__img {
  border-radius: $pm-card-img-top-radius $pm-card-img-top-radius $pm-card-img-bottom-radius $pm-card-img-bottom-radius;
}

.c-pdt-mini__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  //justify-content: space-between;
  order: 2;
  padding: $pm-card-body-padding-y $pm-card-body-padding-x;
  background-color: $pm-card-body-bg-color;
}

.c-pdt-mini__qv {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
}

.c-pdt-mini__flags {
  position: absolute;
  display: flex;
  top: $pm-card-flags-top;
  bottom: $pm-card-flags-bottom;
  left: $pm-card-flags-left;
  right: $pm-card-flags-right;
}

/* TODO put in new component file */
.c-variants {
  display: flex;
  align-items: center;
  font-size: $font-size-sm;
}

.c-variants__item {
  width: 20px;
  height: 20px;
  border: 1px solid $gray-light;
  border-radius: 50%;

  &:not(:first-child) {
    margin-left: $spacer/4;
  }
}

.c-variants__count {
  margin-left: $spacer/4;
}
