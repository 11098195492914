.c-hpshop{
    padding: $spacer*4 0;
}

.c-hpshop-contenttext{
    width: 70%;
    @media (max-width: 1050px){
        width: 100%;
    }
}

.c-hpshop-contenttext__subhr{
    border: 4px solid $black;
    margin-bottom: $spacer*2;
}

.c-hpshop-contenttext__subtext{
    font-size: $lead-font-size;
    text-transform: uppercase;
}

.c-hpshop-contenttext__text{
    font-weight: 700;
}

.c-hpshop-contenttext__hr{
    border: 1px solid $black;
    margin: $spacer*2 0;
}

.c-shop-element{
    align-self: center;
}

.c-shop-contentelement{
    position: relative;
    width: 100%;
    margin-bottom: $spacer*2;
}

.c-shop-contentelement__block {
    display: flex;
}

.c-shop-contentelement__blocktext{
    width: 100%;
    margin-top: $spacer;
}

.c-shop-contentelement_link{
    text-transform: uppercase;
    text-decoration: none;
    color: $black;
    font-weight: 400;
    @include media-breakpoint-mobile () {
        font-size: $spacer*1.25;
    }
}

.c-shop-contentelement_subtext{
    font-size: $spacer*.875;
}

.c-shop-contentelement__blockicon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.c-shop-contentelement__mini{
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-mobile () {
        align-items: flex-start;
    }
}

.c-hpshop-secondcomponent{
    padding-right: $spacer*6;
    padding-left: $spacer*6;
    @include media-breakpoint-mobile () {
        padding-right: 0;
        padding-left: 0;
    }
}