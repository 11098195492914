// *** TOP HEADER ***
@import "../../../../classic-rocket/_dev/css/settings/settings.header";

$topheader-font-family: $headings-font-family;
$topheader-color: #c0bcbb;
$topheader-bg-color: $blue;

// *** HEADER ***

$header-bg: $blue;
$header-border-width: 1px;
$header-border-color: $secondary;

// *** SHORTCUTS ***

//Icon
$header-shortcut-icon-color: #c0bcbb;
$header-shortcut-icon-size: 24px;

//Link
$header-shortcut-color: $header-shortcut-icon-color;
$header-shortcut-color-hover: $white;
$header-shortcut-font-family: $headings-font-family;
$header-shortcut-font-size: 0.875rem;

//Customer
$header-shortcut-customer-color: $header-shortcut-icon-color;

//Cart
$header-shortcut-cart-count-top: -20%;
$header-shortcut-cart-count-right: 10px;
$header-shortcut-cart-count-bg: #5a5d6c;
$header-shortcut-cart-count-color: $white;

