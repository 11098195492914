.c-footer {
  padding: $spacer*4.5 0 $spacer*4;
  background-color: $white;
}

.c-footer-item {
  @include media-breakpoint-mobile () {
    margin-bottom: $spacer;
  }
}

.c-footer-item__title {
  margin-bottom: $spacer;
  font-weight: $headings-font-weight;
  color: $black;
}

.c-footer__newsletter {
  padding: $spacer*2;
  background-color: $gray-light;

  @include media-breakpoint-desktop {
    margin-bottom: $spacer*4;
  }
}
