.c-snap {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* Important for iOS devices */

  /* Hide scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.c-snap.row {
  flex-wrap: nowrap;
}

@include media-breakpoint-mobile {
  .c-snap--mobile {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* Important for iOS devices */

    /* Hide scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.c-snap__item {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  //&:not(:first-child){
  //  margin-left: $spacer;
  //}
}

//for full width item
.c-snap__item--fullw {
  flex: 0 0 100%;

  .img-fluid {
    width: 100%;
  }
}

//with glider active
.c-snap.glider {
  scroll-snap-type: none;

  overflow: -moz-scrollbars-none; //FF
  -ms-overflow-style: none; //IE, Edge
  &::-webkit-scrollbar {
    width: 0; //Chrome, Safari, Opera
  }
}