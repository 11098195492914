.c-checkoutcard-titleproduct {
    text-decoration: none;
    font-size: $h5-font-size;
    color: $black;
    font-weight: 700;
}

.c-checkoutcard-removebtn {
    color: $black;
    display: inline-block;
    font-size: $small-font-size;
    text-decoration: underline ;
    &:hover {
        color: $primary;
    }
}

.page-wrapper--order-confirmation {
    padding-top: $spacer*2;
}
