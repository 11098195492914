.c-checkout-panel__head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 0 auto $spacer*1.5;
  border: none;

  & .nav-link.active {
    border: none;
  }
}

.c-checkout-panel__form {
  width: 100%;
  max-width: 430px;
  margin: auto;

  &--lg {
    max-width: 624px;
  }
}

.c-checkout-card {
  display: flex;
  
  padding: $spacer;
  margin-bottom: $spacer;
  border: solid $gray-light 2px;
  border-radius: 4px;
  transition: .2s;
    
    #checkout-payment-step & {
       flex-direction: column; 
    }

  &.selected {
    border-color: $green;
  }
  label{
    float: none;
  }
    
}

.c-checkout-card__paymt-label{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: $font-weight-bold;

}

.c-checkout-card__head {
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacer;
  margin-bottom: $spacer;
  border-bottom: $gray-light solid 1px;
}

//Carriers estimation

.c-checkout-carrier {
  overflow: auto;

  & > table {
    width: 100%;
    min-width: 400px;
  }
}

.c-checkout-carrier__info {
  display: flex;
  justify-content: center;

  & div:first-of-type {
    margin-right: $spacer;
  }

  @include media-breakpoint-mobile() {
    flex-direction: column;

    & div:first-of-type {
      margin-right: 0;
    }
  }
}

.c-checkout-carrier__row {
  border-bottom: $gray-light solid 1px;

  &:first-of-type {
    border-top: $gray-light solid 1px;
  }

  & td {
    padding: $spacer;
  }
}

.c-checkout-carrier__btn {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-mobile() {
    flex-direction: column;

    & div {
      margin-right: 0;
    }
  }
}


//Modal
.modal-body #colissimo-widget-container {
  height: 100% !important;
  width: 100% !important;
  padding-right: 0 !important;
}

.colissimo_widget_widget .colissimo_widget_h340 {
  width: 100%;
}

.colissimo-front-widget .close {
  background: none;
  border: none;
  order: 2;
}