//Global review
.c-ag {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-ag__img {
  margin-bottom: 1rem;
}

.c-ag__rate {
  margin-bottom: 1rem*.25;
}

//Comment list
.c-pdt-avis {
  margin: auto;
  max-width: 700px;
}

.c-pdt-avis__header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem * 2;
}

.c-pdt-avis__logo {
  @media screen and (max-width: 992px) {
    margin-bottom: 2 * 1rem;
  }
}

.c-pdt-avis__title {
  font-size: .875 * 1rem;
  @media screen and (max-width: 992px) {
    margin-bottom: .625 * 1rem;
    font-size: .75 * 1rem;
  }
}

//.c-pdt-avis__help {
//  width: 16px;
//  height: 16px;
//  color: #ffffff;
//  font-size: 12px;
//  background-color: #555555;
//  border-radius: 50%;
//  border: none;
//  padding: 0;
//}

//Sort
.c-pdt-avis__sort {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.c-pdt-avis__sort-title {
  margin-bottom: 0;
  margin-right: 1rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 0.625 * 1rem;
  }
}

//Comment list content
.c-pdt-avis__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 2.5*1rem;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
}

.c-pdt-avis__note-average {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1rem*3;
}

.c-pdt-avis__note-average--sum {
  font-size: 1rem*2;
}

.c-pdt-avis__note-stars {
  margin-bottom: 1rem;
  text-align: center;
}

.c-pdt-avis__note-sum {
  margin-bottom: 0;
  font-size: 12px;
  text-align: center;
  @media screen and (max-width: 992px) {
    margin-bottom: 1rem;
  }
}

//Product item review
.c-pdt-avis__list-item {
  background: #eee;
  padding: 1.125*1rem 1.25*1rem;
  margin: .625*1rem auto 1rem;
}

.c-pdt-avis__list-title {
  font-size: .875 * 1rem;
  margin-bottom: 1rem*.5;
}

.c-pdt-avis__list-user {
  font-weight: bold;
}

.c-pdt-avis__list-note {
  display: flex;
  align-items: center;
}

.c-pdt-avis__list-stars {
  display: flex;
  margin-right: 1rem;

  & img:not(:last-of-type) {
    margin-right: 1rem*.25;
  }
}

.c-pdt-avis__list-review {
  margin: 20px 0 2px;
}