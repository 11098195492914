.c-wparticle{
    background-color: #eee;
    padding: $spacer*4 0;
}

.c-wparticle_subtitle{
    text-align: center;
    font-size: $lead-font-size;
    margin-bottom: 0;
}

.c-wparticle_title{
    text-align: center;
    font-weight: 700;
    margin-bottom: $spacer*2;
}

.c-wparticle-article{
    position: relative;
    @include media-breakpoint-mobile {
        flex: 0 0 90%;
    }
}

.c-wparticle-img{
    object-fit: cover;
    width: 100%;
    height: 233px;
}

.c-wparticle-article__catdate{
    font-size: $spacer*.875;
    text-transform: uppercase;
    margin-top: $spacer*1.5;
    margin-bottom: $spacer*.5;
}

.c-wparticle-article__title{
    text-decoration: none;
    color: $black;
    font-size: $lead-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.c-wparticle-btn{
    display: flex;
    justify-content: center;
    margin-top: $spacer*2;
}