.c-al-current-ref{
  overflow-x: auto;
}
.c-al-crfmt{
  display: flex;
}
.c-al-crfmt__list{
  display: flex;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  //margin-bottom: $spacer;

}
.c-al-crfmt__item{
  display: flex;
  flex-wrap: nowrap;
  &:not(:first-child){
    margin-left: $spacer;
  }
}
.c-al-crfmt__cat{
  position: relative;
  white-space:nowrap;
  margin-left:.25*$spacer;
  @include button-size(.125rem, .25rem, .75rem, 1, 4px);
  @include button-outline-variant($primary);
  font-weight: $font-weight-base;
  &--modal{
    @include button-outline-variant($white);

  }
}
.c-al-crfmt__del{
  margin-left:.25*$spacer;

}

