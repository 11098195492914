.c-pdt-mini.glider-slide {
  flex: auto;
}
.is-dots-hidden,
.is-arrow-hidden{
  display: none;
}
//.glider-dot {
//  border: 2px solid $black;
//  background: none;
//
//  &.active {
//    background-color: $black;
//  }
//
//  &:hover {
//    background-color: $black;
//  }
//}


.glider-dot{
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: none;
  border: 2px solid $blue;
  width: 30px;
  height: auto;
  margin: 7px;
}

.glider-dots {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  @include media-breakpoint-mobile{ 
    display: none;
  }
}

.glider-dot.active, .glider-dot:focus, .glider-dot:hover{
  outline: none;
  border: 2px solid $primary;
}

.c-btn-arrow {
  background: none;
  width: auto;
  min-width: 40px;
  height: auto;
  border: 1px solid transparent;
}

.c-btn-arrow--prev{
  margin-left: auto;
}