$rckt-item-margin-left:1.5*$spacer!default;

//main item link
$rckt-itemlink-color: #fff !default;
$rckt-itemlink-color-hover: #fff !default;
$rckt-itemlink-font-size: null !default;
$rckt-itemlink-font-family: null !default;
$rckt-itemlink-font-weight: $font-weight-bold !default;
$rckt-itemlink-line-height: $line-height-base !default;
$rckt-itemlink-text-decoration: null !default;
$rckt-itemlink-text-decoration-hover: underline !default;



.c-rcktnav__list{
  display: flex;
  justify-content: space-around;

}

.c-rcktnav__item{
  &:not(:first-child){
    margin-left: $rckt-item-margin-left;
  }
}

.c-rcktnav__itemlink{
  color:$rckt-itemlink-color;
  font-size:$rckt-itemlink-font-size ;
  font-family:$rckt-itemlink-font-family;
  font-weight:$rckt-itemlink-font-weight;
  line-height:$rckt-itemlink-line-height;
  text-decoration: $rckt-itemlink-text-decoration;
  &:hover{
    color:$rckt-itemlink-color-hover;
    text-decoration: $rckt-itemlink-text-decoration-hover;
  }
}


//scope s-menu-abs for default display

.s-menu-abs{
.c-rcktnav__wrap{
  margin-bottom: $menu-margin-bottom;
}

.c-rcktnav__list{
  display: flex;
  justify-content: space-around;
  background-color: $white;
  border: $spacer*.125 solid #e9e9e9;
  border-radius: $spacer;
  padding: $spacer;
}



  .menu-active{
    display: block;
  }
}

