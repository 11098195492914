/**
* Colors
*/


/**
* Text
*/
$text-decoration-primary: underline;

//
$menu-mobile-width:350px;
$menu-margin-bottom:$spacer*(30/16);
$menu-item-padding-vertical:$spacer;
$menu-item-padding:$menu-item-padding-vertical $spacer*2;
$menu-item-font-size:$font-size-sm;
$menu-item-line-height:(19/14);
$submenu-zindex: 5;
$menu-submenu-top:50px;
$submenu-padding:$spacer*2 0;

//widget
$grouplink-margin-bottom:$spacer*2.5;
//item
$grouplink-color:$body-color;
$grouplink-color-hover:$secondary;
$grouplink-font-size:null;
$grouplink-item-margin-bottom:$spacer/2;
//col title
$col-title-text-transform:uppercase;
$col-title-margin-bottom: $spacer*.75;
$col-title-color:$black;
$col-title-font-weight:700;
////MOBILE
$item-m-border-color : transparent;
$item-m-padding : $spacer $spacer*2;