.c-panel {
  padding: $panel-padding-x $panel-padding-y;
  margin-bottom: $panel-margin-bottom;
  background-color: $panel-bg;
}

//Modifiers
.c-panel--sm {
  padding: $panel-sm-padding-x $panel-sm-padding-y;
}

.c-panel--lg {
  padding: $panel-lg-padding-x $panel-lg-padding-y;
}

.c-panel--center {
  max-width: $panel-max-width;
  margin-left: auto;
  margin-right: auto;
}

.c-panel--border {
  border: $panel-border-width solid $panel-border-color;
}

//Header
.c-panel__head {
  padding-bottom: $panel-header-padding-bottom;
  margin-bottom: $panel-header-margin-bottom;
  border-bottom: $panel-header-border-color solid $panel-header-border-width;
}

// Tunnel step header
.js-checkout-step-header.c-panel__head {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

//complete
.-current > .c-panel__head {
  padding-bottom: $panel-header-padding-bottom;
  margin-bottom: $panel-header-margin-bottom;
  border-bottom: $panel-header-border-color solid $panel-header-border-width;
}
