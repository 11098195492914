.s-menu--submenu_modal{

  .c-rcktnav__submenu{
    display: none;
    &.is-submenu-visible{
      display: block;
    }
  }

  .c-submenu__inner{
    margin-top: 2*$spacer;
  }
}