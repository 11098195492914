.al-modal {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
  }

  .modal-content {
    border-radius: 0;
    border: none;
  }

  .al-modal-results {
    display: flex;
    flex-direction: row;

    & > div {
      flex: 1;
    }
  }


}

//no result
.c-almodalsearch__noresult,
.c-almodalsearch__result{
  margin-top: $spacer*3;
}