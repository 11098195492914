.c-al-showmore{
  @include button-outline-variant($primary);
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);

  border-color: transparent;
  text-decoration: underline;
  text-align: center;
  display: block;
  margin-left:auto;
  margin-right:auto;
}
[class].c-al-showmore--disabled{
  display: none;
}