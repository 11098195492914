//thumb settings
$thumb-wrap-width: 100%;
$thumb-wrap-margin: 0 auto 0;

$thumb-border-color: $gray-100;
$thumb-padding: 0;
$thumb-width: 80px;
$thumb-margin-x: 0;
$thumb-margin-y: $spacer*1.5;

$thumb-active-border-color: $primary;


//product name
$pdt-name-font-size : $h2-font-size;
$pdt-name-font-weight : null;
$pdt-name-font-family : null;

$pdt-name-color : null;
$pdt-name-text-transform : null;
$pdt-name-text-decoration : null;
$pdt-name-line-height : null;
$pdt-name-letter-spacing : null;

$pdt-name-margin-bottom : $spacer/2;
//end product name

@import '../../../../classic-rocket/_dev/css/settings/settings.product';