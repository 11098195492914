// Sizes

.u-h1 {
  @extend h1;
}

.u-h2 {
  @extend h2;
}

.u-h3 {
  @extend h3;
}

.u-h4 {
  @extend h4;
}

.u-h5 {
  @extend h5;
}

.u-h6 {
  @extend h6;
}

.u-txt-lead {
  @extend .lead;
}

.u-txt-sm {
  @extend .small;
  font-size: $small-font-size;
}

.u-txt-xs {
  @extend .small;
  font-size: $extra-small-font-size;
}

// Transformation

[class].u-txt-lowercase {
  text-transform: lowercase;
}

[class].u-txt-uppercase {
  text-transform: uppercase;
}

[class].u-txt-capitalize {
  text-transform: capitalize;
}

//font family
[class].u-font-heading {
  font-family: $headings-font-family;
}


// Weight and italics
//[class].u-font-weight-light   { font-weight: $font-weight-light}
//[class].u-font-weight-lighter { font-weight: $font-weight-lighter}
[class].u-font-weight-normal {
  font-weight: $font-weight-normal;
}

[class].u-font-weight-bold {
  font-weight: $font-weight-bold;
}

//[class].u-font-weight-bolder  { font-weight: $font-weight-bolder}
[class].u-font-italic {
  font-style: italic;
}

//line height
[class].u-lh-1 {
  line-height: 1;
}

//State
.u-txt-center {
  text-align: center;
}

//Colors

.u-txt-primary {
  color: $primary;
}

.u-txt-secondary {
  color: $secondary;
}

.u-txt-body {
  color: $body-color;
}

.u-txt-black {
  color: $black;
}

.u-txt-white {
  color: $white;
}

.u-txt-dark {
  color: $dark;
}

.u-txt-gray {
  color: $gray;
}

.u-txt-success {
  color: $success;
}

//Decoration
.u-txt-underline {
  text-decoration: underline;
}