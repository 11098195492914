.c-review-stars {
  width: 100px;
  min-width: 100px;
  height: 15px;
  background: url('../img/bg-reviews.svg');
}

.c-review-stars--alt {
  width: 100px;
  height: 15px;
  background: url('../img/bg-reviews-alt.svg');
}

.c-review-stars--center {
  margin: 0 auto;
}

$reviews: 5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50;
@each $review in $reviews {
  .c-review-stars--#{$review} {
    background: url('../img/bg-reviews.svg'), linear-gradient(to right, $star-color, $star-color #{1.02% * ($review*2)}, $star-empty-color #{1.02% * ($review*2)}, $star-empty-color 100%);
  }
  .c-review-stars--alt-#{$review} {
    background: url('../img/bg-reviews-alt.svg'), linear-gradient(to right, $star-color, $star-color #{1.02% * ($review*2)}, $star-empty-color #{1.02% * ($review*2)}, $star-empty-color 100%);
  }
}