// Command history
.c-history {
  display: flex;
  flex-direction: column;
}

.c-history__item {
  display: flex;
  flex-direction: column;


  &:not(:last-child) {
    padding-bottom: $spacer*2;
    margin-bottom: $spacer*2;
    border-bottom: solid 1px $gray-light;
  }
}

.c-history__infos {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer*2;

  @include media-breakpoint-mobile () {
    flex-direction: column;
  }
}

.c-history__data {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacer*1.5;

  @include media-breakpoint-mobile () {
    &--right {
      text-align: right;
    }
  }
}

.c-history__img {
  position: relative;
  width: 150px;

  @include media-breakpoint-mobile () {
    width: 50%;
  }

  @include media-breakpoint-desktop () {
    &:first-of-type {
      margin-left: $spacer;
    }
  }

  &:not(:last-of-type) {
    margin-right: $spacer;
  }

  &--watermark {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $white;
    background-color: rgba(0, 0, 0, .5);
    @extend .u-h2;
  }
}

// Details command
