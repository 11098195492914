.c-alsearchform{
  position: relative;
}

[class].c-alsearchform__input{
  padding: .5rem 2.2rem;
  width: 100%;

}
.c-alsearchform__submit,
.c-alsearchform__reset {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: none;
  border: none;
}
.c-alsearchform__submit{
  left: 0;
  padding-left: 10px;
  &-icon {
    width: 16px;
    height: 16px;
  }
}
.c-alsearchform__reset{
  right: 0;
  padding-right: 10px;
}


.al-no-results {
  display: none;
}