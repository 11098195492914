//miniature for popup
.c-pdtmini__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-pdtmini__media {
  width: 100%;
  max-width: 160px;
  padding-right: $spacer*1.5;

  img {
    border: 1px solid $border-color;
  }

  @include media-breakpoint-mobile {
    max-width: 120px;

  }
}

.c-pdtmini__iconadd svg {
  width: 26px;
  height: 20px;

}



.btn.is-add-success {
  color: $white;
  background-color: $primary;
  border-color: $primary;

  svg {
    fill: $white;

  }
}

.btn:hover .c-pdtmini__iconadd svg {
  fill: $white;
}

.c-crosselling-pdt-mini {
  display: flex;
  padding-bottom: $spacer*1.5;
  margin-bottom: $spacer*1.5;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }

  &:not(:first-child) {
    margin-top: $spacer*1.5;
  }
}

.c-crosselling-pdt-mini-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-top: auto;
  padding: inherit;
}