.u-d-flex {
  display: flex;
}

.u-d-mobile {
  @include media-breakpoint-desktop () {
    display: none;
  }
}

.u-d-desktop {
  @include media-breakpoint-mobile () {
    display: none;
  }
}

.u-d-none{
display: none;
}

[class].u-d-mobilexs {
  @include media-breakpoint-up (sm) {
    display: none;
  }
}
