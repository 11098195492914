.c-icon {
  & svg {
    display: inline-block;
    vertical-align: text-bottom;
  }
}

.c-icon--center-y {
  & svg {
    display: flex;
    display: -webkit-flex; //Safari fix
    align-items: center;
    -webkit-align-items: center; //Safari fix
  }
}

$sizes: 16, 20, 24, 28, 32, 40;

@each $size in $sizes {
  .c-icon--#{$size} {
    font-size: $font-size-base*($size/16);
  }
}

.c-icon--white{
  color: $white;
}

.c-icon--primary{
  color: $primary;
}
.c-icon--cart{
  font-size: $font-size-base*(28/16);
}
