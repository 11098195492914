.c-inputadd {
  position: relative;
}

.c-inputadd__btn {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
  height: 100%;
}

.c-inputadd--left {
  .c-inputadd__input {
    padding-left: $btn-padding-x*2 + 1.25rem;
  }
}

.c-inputadd--right {
  .c-inputadd__input {
    padding-right: $btn-padding-x*2 + 1.25rem;
  }
  .c-inputadd__btn{
    right: 0;
    left: auto;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid{
  &.c-inputadd__input--right{
    padding-right: ($btn-padding-x * 2 + 1.25rem);
    background-position-x: calc(100% - #{($btn-padding-x * 2 + 1.25rem)});

  }
}

.c-icon--hide{
  display: none;
}
.is-pwd-txt{
  .c-icon--hide{
    display: inline-block;
  }
  .c-icon--show{
    display: none;
  }
}
