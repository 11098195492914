@import "../../../../classic-rocket/_dev/css/components/components.listing";

.c-listing__subcatitem {
    margin-bottom: $spacer;
    border-radius: $spacer*2;
    padding-left: $spacer;
    padding-right: $spacer;

    &:not(:last-child) {
        margin-right: $spacer*.5;
    }
}

.sort-by-row .c-result__select {
    background-color: #E6E6E6;
    color: $gray-900;
    border: none;
}


.c-pdt-rupture {
    position: absolute;
    //z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, .7);
}
