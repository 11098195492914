//product name
$pm-name-font-size : $small-font-size;
$pm-name-color : #333333;

//product brand
$pm-brand-font-size : $font-size-base;
$pm-brand-font-weight : $font-weight-bold;
$pm-brand-font-family: $headings-font-family;
$pm-brand-text-transform : inherit;

$pm-brand-color : $black;
$pm-brand-margin-bottom : 0;

//product thumb
$pm-thumb-border: 1px solid $light;
$pm-thumb-margin-bottom: $spacer*.5;

@import '../../../../classic-rocket/_dev/css/settings/settings.product-mini';