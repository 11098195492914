.c-cart-summary {
  background-color: $white;

  @media (min-width: 992px) {
    position: -webkit-sticky;
    position: sticky;
    top: $spacer*1.5;
  }
}

.c-cart-summary__row {
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacer*.5;
  margin-bottom: $spacer*.5;
  border-bottom: $gray-light solid 1px;
}

.c-cart-summary__mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: none;
  width: 100%;
  padding: $spacer $spacer*2;
  background-color: $white;
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);

  @include media-breakpoint-mobile () {
    display: block;
  }
}

.c-cart__row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-mobile () {
    flex-direction: column;
  }
}

.c-cart__row--border {
  padding-bottom: $spacer*.5;
  margin-bottom: $spacer*.5;
  border-bottom: solid 1px $gray-light;
}

.c-cart__caracts {
  display: flex;

  @include media-breakpoint-mobile () {
    margin-bottom: $spacer;
  }
}

.c-cart__img {
  @include media-breakpoint-mobile () {
    width: 80px;
    height: auto;
  }
}

.c-cart__price {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include media-breakpoint-mobile () {
    flex-direction: column;
    align-items: flex-end;
  }
}
