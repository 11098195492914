
//facet btn
.c-facet__btn{
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
  @include button-variant($primary,$primary);
}

.c-al-btn-clearall{
  @include button-outline-variant($primary);
  &.disabled{
    display: none;
  }
  &.c-al-btn-clearall--top{
    white-space:nowrap;
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);


  }

}

.c-al-clearall__wrapper{
  margin-left: 30px;
}