.c-btn--nopad {
  @extend .btn-link;
  padding: 0;
}

.c-btn--unstyled {
  padding: 0;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}

.c-btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.c-btn-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(#{$btn-line-height*$btn-font-size+$btn-padding-y*2} + #{($btn-border-width*2)});
  height: calc(#{$btn-line-height*$btn-font-size+$btn-padding-y*2} + #{($btn-border-width*2)});
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  &--lg {
    width: calc(#{$btn-line-height*$btn-font-size-lg+$btn-padding-y-lg*2} + #{($btn-border-width*2)});
    height: calc(#{$btn-line-height*$btn-font-size-lg+$btn-padding-y-lg*2} + #{($btn-border-width*2)});
  }
}


.c-btn--underline {
  position: relative;
  display: flex;
  align-self: flex-start;
  font-weight: $headings-font-weight;
  color: $primary;

  &:hover {
    color: $primary;
  }

  &::before {
    position: absolute;
    bottom: -$spacer*.25;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background-color: $primary;
  }
}

//button for carousels / sliders
$btn-arrow-width:40px;
$btn-arrow-height:40px;
.c-btn-arrow{
  width: $btn-arrow-width;
  //for flex child item
  min-width: $btn-arrow-width;
  height: $btn-arrow-height;
  border: 1px solid transparent;
  border-radius: 50%;
}
.c-btn-arrow--abs{
  position: absolute;
  top: calc(50% - #{$btn-arrow-height/2});
  &.c-btn-arrow--next{
    right: 0;
  }
  &.disabled{
    opacity: .7;
    cursor: not-allowed;
  }
}



