.c-filter {
  padding-bottom: $spacer;
  margin-bottom: $spacer;
  border-bottom: solid 1px $secondary;
}

.c-filter__title {
  margin-bottom: $spacer*.5;

  & a {
    font-weight: $headings-font-weight;
    color: $black;
  }
}

.c-filter__row {
  margin-bottom: $spacer*.5;

  & a {
    color: $black;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
