$gray-900: #141414;
$blue: #363948;
$orange: #fc7c3c;
$link-color: $orange;

$primary: $orange;
$secondary: $blue;

$gray-400 : #b3b3b3;
$gray-300 : #ccc;
$gray-100: #D9D9D9;

$background : #fff;

$altcolor : #B30000;

$link-color : $primary;

@import "../../../../classic-rocket/_dev/css/settings/settings.colors";
