.c-form__row {
  display: flex;
  align-items: center;
  margin-bottom: $spacer*1.5;
}

.c-form__label,
.form-group > label{


  @include font-size($form-label-font-size);
  color: $form-label-color;
  font-weight: $form-label-font-weight;
  font-family: $form-label-font-family;
  text-transform: $form-label-text-transform;


  &--sm {
    font-size: $font-size-sm;
  }
}

.c-form__quantity {
  & > div {
    display: flex;
    width: 300px;
  }
}

.c-form__footer {
  display: flex;
  justify-content: center;
  margin: 0 auto $spacer*1.5;

}
.s-login-form-checkout{
  .c-form__footer {
    margin-bottom: 0;

  }
}


//

.c-form__mw-450 {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.c-form__mw-630{
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.c-form__inputrow{
  display: flex;
  flex-wrap: wrap;
  .form-group{
    margin-right: $spacer;
    &:last-child{
      margin-right: 0;
    }
  }

  .c-form-group__firstname,
  .c-form-group__lastname{
    width: calc(50% - #{$spacer/2});
    min-width: 300px;
    max-width: 100%;
  }
  .c-form-group__postcode{
    max-width: 150px;

  }
  .c-form-group__id_country{
    max-width: 200px;

  }




  .c-form-group__city{
    min-width: 230px;
    flex: 1;
  }

}
.c-form--withstates{
  .c-form-group__city{
    flex: 1 1 100%;
    margin-right: 0;
  }
}

.c-form-group__phone_mobile,
.c-form-group__phone
{
  max-width: 320px;

}

