@import '../../../../../modules/rocketnetreviews/_dev/src/scss/components/components.gauge';
@import '../../../../../modules/rocketnetreviews/_dev/src/scss/components/components.pagination';
@import '../../../../../modules/rocketnetreviews/_dev/src/scss/components/components.pdt-review';
@import '../../../../../modules/rocketnetreviews/_dev/src/scss/components/components.pdt-global-reviews';
@import '../../../../../modules/rocketnetreviews/_dev/src/scss/components/components.site-global-reviews';
@import '../../../../../modules/rocketnetreviews/_dev/src/scss/components/components.stars';

//Global reviews
.c-ag__rate {
  display: flex;
  align-items: center;
  margin-bottom: $spacer*.5;

  & svg {
    fill: #FB8F04;
  }
}

.c-global-reviews__item-date {
  color: #404040;
  font-size: $extra-small-font-size
}

//Top review (Pdt page)
.c-review__link {
  color: $dark;
}

//Comment list content
.c-pdt-avis__detail {
  justify-content: space-around;
  flex-wrap: nowrap;
  margin-bottom: $spacer*2;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
}

.c-pdt-avis__note {
  display: flex;
  align-items: center;

  @include media-breakpoint-mobile {
    margin-bottom: $spacer*2;
  }

  & svg {
    fill: #FB8F04;
  }
}

.c-pdt-avis__note-sum {
  text-align: right;
  margin-bottom: 0;
}

//Gauge
.c-pdt-avis__gauge-bar {
  position: relative;
  max-width: 380px;
  min-width: 80px;
  width: 230px;
  height: 10px;
  background: rgba(200, 200, 200, 0.50);
  border-radius: 5px;

  @media screen and (max-width: 992px) {
    width: 40vw;
  }
}

//Product item review
.c-pdt-avis__list-item {
  background: $white;
  border: solid 1px #DADADA;
  border-radius: 4px;
  padding: $spacer;
  margin: .625*1rem auto 1rem;
}

.c-pdt-avis__list-review {
  margin: 0 0 $spacer;
}

//Slider
.c-reviews-slider__dots {
  @include media-breakpoint-mobile {
    margin-bottom: $spacer*1.5;
  }

  & .glider-dot {
    width: 40px;
    height: 2px;
    border: none;
    background-color: $white;

    &.active {
      background-color: $primary;
    }
  }
}