/* Content entered by WYSIWYG [component]
========================================================================== */
.s-cms{
  img{
    max-width: 100%;
    height: auto;
  }
}

.s-cms-list {
  list-style: inherit;
  padding-left: $spacer*1.25;
  margin-bottom: $spacer;
}