.c-search__btnicon {
    padding-top: $search-padding-x/2;
    padding-bottom: $search-padding-x/2;
    padding-left: $search-padding-y;
    padding-right: $search-padding-y;
    font-size: $search-icon-size;
    color: #c0bcbb;

}

.c-search__input {
    font-size: $search-font-size;
    font-family: $search-font-family;
    padding-left: $search-padding-left;
    background-color: $search-bg-color;
    border-color: $primary;
    color: #c0bcbb;
    border-radius: $search-border-radius;

    &::placeholder {
        color: #c0bcbb;
    }
}
