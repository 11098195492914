/* Montserrat-300 - latin */
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("../fonts/Montserrat-Light.woff2") format("woff2"),
        url("../fonts/Montserrat-Light.woff") format("woff"),
        url("../fonts/Montserrat-Light.ttf") format("truetype");
}

/* Montserrat-regular - latin */
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
        url("../fonts/Montserrat-Regular.woff") format("woff"),
        url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

/* Montserrat-Bold - latin */
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/Montserrat-Bold.woff") format("woff"),
        url("../fonts/Montserrat-Bold.ttf") format("truetype");
}
